import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    defaultAssets: {
        font: false, // Disabilita Roboto
        icons: 'mdi', // Se usi Material Design Icons
    },
    ttheme: {
        themes: {
            light: {
            if65Blue: '#414141',
            italbrix: '#ba1d28',
            if65Silver: '#b3b6b7',
            background: '#f1f1f1',

            light: {
            greyLight: '#F5F5F5',  // Vuetify: grey lighten-4
            greyMedium: '#E0E0E0', // Vuetify: grey lighten-2
            greyDark: '#424242',   // Vuetify: grey darken-3
            accentBlue: '#64B5F6', // Vuetify: blue lighten-2
            accentRed: '#E57373',  // Vuetify: red lighten-2
            accentYellow: '#FFF176', // Vuetify: yellow lighten-2
            accentGreen: '#81C784', // Vuetify: green lighten-2
        },
        },
        }
    },
    options: {
        customProperties: true
    },
    icons: {
        iconfont: 'mdi',
        values: {
            dashboard: 'mdi-view-dashboard',
            project: 'mdi-projector-screen-outline',
            exit: 'mdi-exit-to-app',
            team: 'mdi-account-group'
        }
    },
    typography: {
        fontFamily: 'Poppins, sans-serif', // Cambia 'Roboto' con il font desiderato
    },
});
