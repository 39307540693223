<template>
  <td :colspan="colSpan">
    <v-data-table
        :headers="productHeaders"
        :items="products"
        class="elevation-2"
        dense
        hide-default-footer
        :items-per-page="20"
    >
      <template #item.show_alert="{ item }">
        <v-btn v-if="item.show_alert.length > 0" icon>
          <v-icon small>
            mdi-bell-badge-outline
          </v-icon>
        </v-btn>
      </template>

      <template #item.status="{ item }">
        <v-select
            v-if="status"
            v-model="item.status_value"
            :items="status"
            item-value="value"
            item-text="text"
            dense
            @change="patchProductStatus(item)"
        ></v-select>
      </template>

      <template #item.provider="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                text
                small
                @click="showProvider(item)"
            >{{ item.provider }}
            </v-btn>
          </template>
          <span>Guarda i Dettagli del Fornitore</span>
        </v-tooltip>
      </template>

      <template #item.end_date="{ item }">
        {{ formatDate(item.end_date) }}
      </template>

      <template #item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }" v-if="item.subactivities > 0">
            <v-btn
                v-bind="attrs"
                :v-on="on"
                v-if="canInsertTask(item)"
                color="primary"
                x-small
                @click.stop="openTaskDialog(item)">
              <v-icon small>mdi-clock-time-eight-outline</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi Ore</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="canInsertTask(item)"
                color="primary ml-2"
                x-small
                @click.stop="openTaskDetails(item)">
              <v-icon small>mdi-card-account-details-outline</v-icon>
            </v-btn>
          </template>
          <span>Guarda i Dettagli dell'attività</span>
        </v-tooltip>

        <task-dialog
            v-if="showTaskDialog"
            v-model="showTaskDialog"
            :product="product"
            @taskSaved="onTaskSaved"/>
      </template>
    </v-data-table>
    <v-card v-if="showTaskDetails" elevation="0" class="pb-3 background mt-5">
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto">
                <v-card-title style="color: #455A64">
                  <h5 style="text-decoration: underline">Cod. Progetto:</h5>
                </v-card-title>
                <v-card-subtitle>{{ details.project_code }}</v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="2">
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto">
                <v-card-title style="color: #455A64">
                  <h5 style="text-decoration: underline">N° d'ordine:</h5>
                </v-card-title>
                <v-card-subtitle v-if="details.order_number">{{ details.order_number }}</v-card-subtitle>
                <v-card-subtitle v-else>-- no-data --</v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="3">
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto">
                <v-card-title style="color: #455A64">
                  <h5 style="text-decoration: underline">Descrizione:</h5>
                </v-card-title>
                <v-card-subtitle v-if="details.description" v-html="details.description"></v-card-subtitle>
                <v-card-subtitle v-else>-- no-data --</v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="2">
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto">
                <v-card-title style="color: #455A64">
                  <h5 style="text-decoration: underline">Data di Consegna:</h5>
                </v-card-title>
                <v-card-subtitle v-if="details.delivery_date">{{ details.delivery_date }}</v-card-subtitle>
                <v-card-subtitle v-else>-- no-data --</v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="2">
            <v-hover
                v-slot="{ hover }"
                open-delay="200"
            >
              <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" class="mx-auto">
                <v-card-title style="color: #455A64">
                  <h5 style="text-decoration: underline">Allegati:</h5>
                </v-card-title>
                <v-card-subtitle v-if="details.note_attachment">
                  <a
                      :href="details.note_attachment"
                      target="_blank"
                  >
                    {{ details.note_name[0] }}
                  </a>
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
        v-model="showProviderDetails"
        max-width="600"
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >Dettagli Fornitore: {{ this.provider.organization_name }}
        </v-toolbar>

        <v-card-text>
          <div class="pa-3">
            <v-row>
              <v-col>
                Descrizione Invio File: <b>{{ this.provider.send_file_description }}</b>
                <hr>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="text-align: center">
                <b>DETTAGLI:</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div v-for="(contatto, index) in emails" :key="index" class="mt-2">
                  Email: <b>{{ contatto.email }}</b>
                  <span class="float-end">Telefono:  <b>{{ contatto.phone }}</b></span>
                  <hr>
                </div>
              </v-col>
            </v-row>

          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green darken-1"
              text
              @click="showProviderDetails = false"
          >
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </td>
</template>

<script>
import TaskDialog from "./TaskDialog.vue";
import axios from "axios";
import {format, parseISO} from "date-fns";

export default {
  components: {TaskDialog},
  props: {
    products: {
      type: Array,
      required: true
    },
    operators: {
      type: Array,
      required: true
    }
  },
  computed: {
    colSpan() {
      return 5 + this.operators.length
    }
  },
  data() {
    return {
      product: null,
      details: [],
      productHeaders: [
        {text: 'Alert', value: 'show_alert', class: "if65Blue text-dark", align: 'start', sortable: true, width: "7%"},
        {text: 'Stato', value: 'status', class: "if65Blue text-dark", align: 'start', sortable: false, width: '15%'},
        {text: 'Nome Prod.', value: 'name', class: "if65Blue text-dark"},
        {text: 'Ore previste', value: 'scheduled_hours', class: "if65Blue text-dark"},
        {text: 'Diff. in Ore', value: 'delta_hours_signed', class: "if65Blue text-dark"},
        {text: 'Diff. in %', value: 'delta_hours_perc_signed', class: "if65Blue text-dark"},
        {text: 'Fornitore', value: 'provider', class: "if65Blue text-dark"},
        {text: 'Data Consegna', value: 'end_date', class: "if65Blue text-dark"},
        {text: 'Azioni', value: 'actions', class: "if65Blue text-dark", sortable: false},
      ],
      showTaskDialog: false,
      showTaskDetails: false,
      showProviderDetails: false,
      status: [],
      provider: '',
      emails: [],
      number_sub_activity: null,
      downloadFileResponse: ''
    };
  },
  mounted() {
    this.initStatus();
  },
  methods: {
    patchProductStatus(item) {
      this.$productService.patch(item.id, {status: item.status_value})
          .then(() => {
            this.$emit('taskSaved')
          })
          .catch()
    },
    initStatus() {
      this.$productService.status_choices()
          .then(resp => {
            this.status = resp.data
          })
    },
    canInsertTask(item) {
      return item.operators.includes(this.$store.state.user.id)
    },
    onTaskSaved() {
      this.showTaskDialog = false;
      this.$emit('taskSaved');
    },
    openTaskDialog(item) {
      this.$productService.get(item.id).then(
          resp => {
            this.product = resp.data;
            if (resp.data.sub_activities_names.length > 0) {
              return this.showTaskDialog = true;
            }
            this.$dtoast.pop({
              preset: "error",
              content: "non hai sotto attività assegnate!",
            })
            return this.showTaskDialog = false;
          }
      )
    },
    openTaskDetails(item) {
      this.$productService.get(item.id).then(
          resp => {
            this.details = resp.data;
            this.showTaskDetails = !this.showTaskDetails
          }
      )
    },
    showProvider(item) {
      this.$providerService.get(item.provider_id)
          .then(resp => {
            this.provider = resp.data
            this.showProviderDetails = true
            console.log(this.provider)
            this.$providerService.getEmails(item.provider_id)
                .then(resp => {
                  this.emails = resp.data
                })
          })
          .catch(error => {
            console.log(error)
          })
      console.log('id provider: ', item.provider_id)
    },
    async downloadItem({url, label}) {
      const response = await axios.get(url, {responseType: "blob"});
      const blob = new Blob([response.data],);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    formatDate(isoDate) {
      return isoDate ? format(parseISO(isoDate), 'dd/MM/yyyy') : '-';
    }
  }

}
</script>


