<template>
  <nav>
    <v-app-bar
        class="elevation-0"
        color="white"
        flat
        dark
        dense
        app>
      <v-app-bar-nav-icon>
        <v-icon color="grey darken-1" @click="drawer = !drawer">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-chip
          color="green"
          text-color="white"
          v-if="$store.state.user.role === 'ADMIN'">{{ $store.state.user.role }}
      </v-chip>
      <Alert :alert="alert"/>
      <v-toolbar-title class="text-uppercase"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
          min-width="200px"
          rounded
          offset-y>

        <template v-slot:activator="{ on }">
          <v-btn
              class="mr-4"
              icon
              small
              v-on="on">

            <v-avatar
                color="blue lighten-2"
                size="36">
              <span class="if65Blue--text text-h5">{{ user.initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                  color="blue">
                <span class="white--text text-h5">{{ user.initials }}</span>
              </v-avatar>
              <h3>{{ user.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ user.email }}
              </p>
              <v-btn
                  depressed
                  rounded
                  text
              >
                Edit Account
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  depressed
                  rounded
                  text
                  @click="logout"
                  class="red--text lighten-3"
              >
                Logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" color="white" style="max-width: 250px;">
      <v-list dense>
        <v-list-item color="blue">
          <v-list-item-content>
            <v-list-item-title class="title">
              <v-img src="@/assets/logo_small.png" width="100px"></v-img>
            </v-list-item-title>
            <v-list-item-subtitle class="white text-blue">
              Task Manager Admin
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="mr-3">
        <v-list-item-group
            color="blue"
            v-for="item in items"
            :key="item.title">
          <v-list-item :to="item.link">
            <v-list-item-icon>
              <v-icon v-text="item.action"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-database-clock-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Backup</v-list-item-title>
          </template>
          <v-list-item-content>
            <router-link
                style="text-decoration: none"
                class="v-expansion-panels--hover"
                :to="{ name: 'backup', params: { anno_riferimento: link.params.search }}"
                v-for="(link, i) in links"
                :key="i">
              <v-list-item class="v-list-item">
                <v-list-item-icon>
                  <v-icon v-text="link.action"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="link.title"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-item-content>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import Alert from "./widgets/Alert";

export default {
  name: "Navbar",
  components: {Alert},
  data() {
    return {
      drawer: true,
      // alert: false,
      user: {
        initials: `${this.$store.state.user.first_name[0]}${this.$store.state.user.last_name[0]}`,
        fullName: this.$store.state.user.full_name,
        email: this.$store.state.user.email,
      },
      links: []
      // links: [
      //   {title:'2022', action:'mdi-update', link:'/progetti', params:{ search:2022 }},
      //   {title:'2021', action:'mdi-update', link:'/progetti', params:{ search:2021 }},
      //   {title:'Template', action:'mdi-blur', link:'/progetti', params:{ search:2000 }},
      // ],
    }
  },
  created() {
    // Aggiungi il link per l'anno 2000
    this.links.push({
      title: 'Template',
      action: 'mdi-blur',
      link: '/progetti',
      params: {search: 2000},
    });
    this.links.push({
      title: '2025',
      action: 'mdi-update',
      link: '/progetti',
      params: {search: 2025},
    });
    // Genera dinamicamente i link per gli anni dal 2021 fino all'anno corrente
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= 2021; year--) {
      this.links.push({
        title: year.toString(),
        action: 'mdi-update',
        link: '/progetti',
        params: {search: year},
      });
    }
  },
  computed: {
    items() {
      const items = []
      if (this.$store.state.user.role !== 'MANAGEMENT_CONTROL') {
        items.push({action: 'mdi-format-list-bulleted-square', title: 'Progetti', link: '/projects',},);
      }
      if (['ADMIN', 'HEAD'].includes(this.$store.state.user.role)) {
        items.push({action: 'mdi-account-arrow-left', title: 'Insegne', link: '/customers'})
        items.push({action: 'mdi-account-arrow-right', title: 'Fornitori', link: '/providers'})
        // items.push({action: this.$icons.backup, title: 'Backup', link:'/'})
      }
      items.push({action: this.$icons.task, title: 'Tasks', link: '/tasks'})
      return items;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({name: 'login'});
        this.$dtoast.pop({
          preset: "success",
          content: "Logout dal Sistema",
        })
      }).catch(error => {
        this.$dtoast.pop({
          preset: "error",
          content: `Logout dal Sistema non riuscito: ${error}`,
        })
      })
    },
    onClick(link) {
      this.$router.push(link)
    },
    alert(e) {
      console.log(e)
    },
    linkToBackup(year) {
      let params = {
        anno_riferimento: year
      }
      this.$projectService.list(params)
          .then(resp => {
            console.log(resp)
          })
      console.log(year)
    }
  }
}
</script>

<style scoped>
.border {
  color: #3cd1c2;
}

.v-list-item--active .v-list-item--link {
  color: #3cd1c2;
}
</style>
