<template>
  <v-app id="app">
    <Navbar v-if="showAppDrawer"/>
    <v-main class="mx-6 mt-3">
      <router-view></router-view>
    </v-main>
    <v-footer v-if="showAppDrawer" app>
      <small><a href="https://mumble.group" target="_blank" style="color: darkorange">Mumble Group© </a>
        {{ new Date().getFullYear() }} | all-right reserved to IF65 srl Version Product: 1.2.5</small>
    </v-footer>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar";

export default {
  name: 'App',
  components: {
    Navbar,
  },
  created() {
    this.$store.dispatch('setCurrentUser')
    this.$root.$on('renderError', xhr => this.onRenderError(xhr));
  },
  computed: {
    showAppDrawer() {
      return this.$store.state.user && this.$route.name !== 'login';
    }
  },
  methods: {
    onRenderError(xhr) {
      const status = xhr.response.status
      const data = xhr.response.data
      if (status === 400) {
        if (Array.isArray(data)) {
          this.renderError(data.join(', '))
        } else {
          Object.entries(data).forEach(
              ([key, value]) => {
                if (key === 'non_field_errors') {
                  for (const nfe of value) {
                    this.renderError(nfe)
                  }
                } else {
                  this.renderError(`${key}: ${value}`)
                }
              }
          );
        }
      }
    },
    renderError(errorString) {
      this.$dtoast.pop({
        preset: "error",
        content: errorString
      })
    }
  }
};
</script>
<style>
html, body {
  font-family: 'Poppins', sans-serif !important;
}

.v-application {
  font-family: 'Poppins', sans-serif !important;
}

hr {
  border: 0;
  height: 1px;
  background: white;
}
</style>
